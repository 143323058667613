import React, { useEffect } from "react"
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup"
import { Box, Button, Form, FormGroup, Input } from "@bigcommerce/big-design"
import { useFormik } from 'formik'

import { useStateValue } from '../store/state'

const RegisterForm = () => {
	const { state, actions } = useStateValue()
	const navigate = useNavigate()

	useEffect(() => {
        if (state.merchant_data) {
            if (state.merchant_data.isRegistered) {
                return navigate(`/dashboard`)
            }
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ state.merchant_data ])

	const formik = useFormik({
		initialValues: {
			merchantId: '',
			clientId: '',
			clientSecret: '',
		},
		validationSchema: Yup.object({
			merchantId: Yup.string().required('Merchant ID is required'),
			clientId: Yup.string().required('Client ID is required'),
			clientSecret: Yup.string().required('Client Secret is required'),
		}),
		onSubmit: async (values) => {
			actions.registerMerchant(values)
		}
	})

	return (
		<div id='reg-form-container'>
			<div id="reg-form">
				<Form onSubmit={formik.handleSubmit} noValidate>
					<FormGroup>
						<Input
							label="Merchant ID"
							name="merchantId"
							type="text"
							required
							error={formik.touched.merchantId && formik.errors.merchantId}
							{...formik.getFieldProps('merchantId')}
						/>
					</FormGroup>
					<FormGroup>
						<Input
							label="Client ID"
							name="clientId"
							type="text"
							required
							error={formik.touched.clientId && formik.errors.clientId}
							{...formik.getFieldProps('clientId')}
						/>
					</FormGroup>
					<FormGroup>
						<Input
							label="Client Secret"
							name="clientSecret"
							type="password"
							required
							error={formik.touched.clientSecret && formik.errors.clientSecret}
							{...formik.getFieldProps('clientSecret')}
						/>
					</FormGroup>
					<Box marginTop="xLarge">
						<Button type="submit">
							Connect
						</Button>
					</Box>
				</Form>
			</div>
			<div id="instructions-sidebar">
				<h2>
					Installation of this Application requires a Listrak Account
				</h2>
				<div class="instruction-text">
					If you don't have a Listrak account, please visit <a rel="noreferrer" href="https://www.listrak.com/get-started" target="_blank">Listrak's website</a> to 
					contact a sales rep.
				</div>
				<div class="instruction-text">
					If you have an active Listrak account then view our <a rel="noreferrer" href="https://help.listrak.com/en/articles/5540976-bigcommerce-integration-guide" target="_blank">integration setup guide</a> on our help center to get started.
				</div>
			</div>
		</div>
	);
}

export default RegisterForm
