import { useStateValue } from '../store/state'
import { ProgressCircle } from "@bigcommerce/big-design";

const Loader = () => {
    const { state } = useStateValue()

    if (!state.loading) {
        return null
    }

    return (
        <div className="loader">
            <ProgressCircle size="large" />
        </div>
    )
}

export default Loader;