import { createContext, useContext, useReducer } from 'react'
import { reducer, initialState } from './reducers'
import { useActions } from './actions'
import { applyMiddleware } from './middleware'

export const StateContext = createContext()

export const StateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	const enhancedDispatch = applyMiddleware(state, dispatch)
	const actions = useActions(state, enhancedDispatch)

	return (
		<StateContext.Provider value={{ state, actions }}>
			{children}
		</StateContext.Provider>
	)
}

export const useStateValue = () => useContext(StateContext)

