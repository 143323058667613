import React, { useEffect,  useState } from 'react'
import { useNavigate  } from 'react-router-dom'
import { Box, Tabs } from "@bigcommerce/big-design"
import styled from 'styled-components';

import { useStateValue } from '../store/state'

import Help from "./Help"
import Settings from "./Settings"
import Status from "./Status"

const Dashboard = () => {
	const navigate = useNavigate()
	
	const { state } = useStateValue()
	const [ activeTab, setActiveTab ] = useState('settings')

	// Extra safeguard against users 
	// accessing the dashboard directly without being 
	// registered -- probably could be handled in the
	// middleware component /shrug
	useEffect(() => {
		if (state.merchant_data && !state.merchant_data.isRegistered) {
			return navigate(`/register`)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ state.merchant_data?.isRegistered ])

	const tabs = [
		{ 
			id: 'settings', 
			title: 'Settings' 
		},
		{ 
			id: 'status', 
			title: 'Status' 
		},
		{ 
			id: 'help', 
			title: 'Help' 
		},
	];

	const StyledBox = styled(Box)`
		margin: ${({ theme }) => theme.spacing.xxLarge};

		${({ theme }) => theme.breakpoints.mobile} {
			margin: ${({ theme }) => theme.spacing.none};
		}
	`;

	return (
		<div className='dashboard'>
			<div className='dashboard-tabs'>
				<Box margin="large">
					<Tabs activeTab={activeTab} items={tabs} onTabClick={setActiveTab} />
				</Box>
			</div>
			<div>
				<StyledBox>
					{ activeTab === 'settings' && <Settings /> }
					{ activeTab === 'status' && <Status /> }
					{ activeTab === 'help' && <Help /> }
				</StyledBox>
			</div>
		</div>
	)
}

export default Dashboard