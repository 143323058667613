import { useStateValue } from '../store/state'
import { Modal, Text } from "@bigcommerce/big-design";

const Error = () => {
    const { state, actions } = useStateValue()

    var modalText = "We are sorry but an unexpected error has occurred.  Please refresh and try again.<br/>If you continue to encounter this error please contact customer service at 1-800-555-1234.";

    const handleClose = () => {
      actions.resetError()
    }

    if (!state.error) {
      return null
    }

    if(state.error.code !== 500) {
      modalText = state.error.message;
    } 


    return (
        <div className="dialog--error">
            <Modal
                actions={[{ 
                        text: 'Close', 
                        onClick: handleClose
                    },
                ]}
                header="Sorry, an unexpected error has occurred!"
                isOpen={true}
                onClose={handleClose}
                closeOnEscKey={true}
                closeOnClickOutside={false}
                variant="dialog"
            >
                <Text data-message={state.error.message}>
                  {modalText}                    
                </Text>
            </Modal>
        </div>
    )
}

export default Error;