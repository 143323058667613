import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom'
import { StateProvider } from './store/state'
import Container from './components/Container'
import Load from './components/Load'
import RegisterForm from './components/RegisterForm'
import Dashboard from './components/Dashboard'
import Loader from './components/Loader'
import Error from './components/Error'

const App = () => {
	return (
		<StateProvider>
			<Router>
				<Container>
					<Routes>
						<Route exact path='/load' element={<Load/>}/>
						<Route path='/register' element={<RegisterForm/>}/>
						<Route path='/dashboard' element={<Dashboard/>}/>
						<Route path='/' element={<div>... TODO:  Not connected ...</div>}/>
					</Routes>
				</Container>
			</Router>
			<Loader />
			<Error />
		</StateProvider>
	)
}

export default App;