import { getJSON, postJSON } from '../utils/http'
import { buildSignedURL } from '../utils/url'

const SERVICE_ROOT = process.env.REACT_APP_API_GW

export const ACTION_TYPES = {
    SHOW_LOADER: `show_loader`,
    HIDE_LOADER: `hide_loader`,
    RESET_ERROR: `reset_error`,
    RAISE_ERROR: `raise_error`,
    SET_SIGNED_HASH: `set_signed_hash`,
    SET_SIGNED_PAYLOAD: `set_signed_payload`,
    GET_MERCHANT_DATA: `get_merchant_data`,
    SET_MERCHANT_DATA: `set_merchant_data`,
    CONNECT_MERCHANT_LISTRAK: `connect_merchant_listrak`,
    SUBMIT_CONTACT_FORM: `submit_contact_form`,
    SET_CONTACT_RESPONSE: `set_contact_response`,
    TOGGLE_WEBHOOK: `toggle_webhook_state`
}

export const useActions = (state, dispatch) => {
    /**
     * Dispatch a show loader action
     */
    const showLoader = () => {
        dispatch({
            type: ACTION_TYPES.SHOW_LOADER,
        })
    }

    /**
     * Dispatch a hide loader action
     */
    const hideLoader = () => {
        dispatch({
            type: ACTION_TYPES.HIDE_LOADER,
        })
    }

    /**
     * Dispatch a reset error action
     */
    const resetError = () => {
        dispatch({
            type: ACTION_TYPES.RESET_ERROR,
        })
    }

    /**
     * Dispatch a raise error action.  
     * 
     * @param {*} error 
     */
    const raiseError = (error) => {
        hideLoader()
        dispatch({
            type: ACTION_TYPES.RAISE_ERROR,
            payload: error,
        })
    }

    /**
     * Dispatch a set signed data action.  The URL Query String will be deconstructed
     * and parameters signed_hash and signed_payload extracted.  
     * 
     * @param {*} query 
     */
    const setSignedData = (query) => {
        const signedHash = query.get('signed_hash')
        const signedPayload = query.get('signed_payload')

        if (signedHash) {
            setSignedHash(signedHash)
        }

        if (signedPayload) {
            setSignedPayload(signedPayload)
        }    
    }

    /**
     * Dispatch a set signed hash action.
     * 
     * This value comes from the middleware and represents the store hash as a signed jwt.
     * This will be the primary value used to authenticate requests against the middleware.
     * 
     * @param {*} signed_hash 
     */
    const setSignedHash = (signed_hash) => {
        dispatch({
            type: ACTION_TYPES.SET_SIGNED_HASH,
            signed_hash,
        })

        // todo:  this might not reflect the updated signed_hash value
        // this probably isn't an issue - but should be considered
        loadMerchantData(signed_hash, null)
    }

    const setContactResponse = (message, status) => {
      dispatch({
        type: ACTION_TYPES.SET_CONTACT_RESPONSE,
        message,
        status
      });
    };

    /**
     * Dispatch a set signed payload action.
     * 
     * This value comes from BigCommerce and is used to authenticate the initial /load request.
     * 
     * @param {*} signed_payload 
     */
    const setSignedPayload = (signed_payload) => {
        dispatch({
            type: ACTION_TYPES.SET_SIGNED_PAYLOAD,
            signed_payload,
        })

        // todo:  this might not reflect the updated signed_hash value
        // this probably isn't an issue - but should be considered
        loadMerchantData(null, signed_payload)
    }

    /**
     * 
     * @param {*} signed_hash 
     * @param {*} signed_payload 
     */
    const loadMerchantData = (signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
        showLoader()

        getJSON(`${SERVICE_ROOT}/load?${buildSignedURL(signed_hash, signed_payload)}`)
        .then(data => {
            hideLoader()
            setMerchantData(data)
        })
        .catch((e) => {
            raiseError({
                message: e.message || `Error while calling /load`,
                debug: {
                    error: e,
                    url: `${SERVICE_ROOT}/register?${buildSignedURL(state.signed_hash, state.signed_payload)}`,
                }
            })
        })
        .finally(() => {
            //
        })
    }

    /**
     * Handle the request by calling corresponding AWS Lambda function
     * 
     * @param {*} data 
     * @param {*} signed_hash 
     * @param {*} signed_payload 
     */
    const registerMerchant = (data, signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
        showLoader()

        postJSON(`${SERVICE_ROOT}/register?${buildSignedURL(signed_hash, signed_payload)}`, data)
        .then(data => {
            hideLoader()
            setSignedHash(data.signed_hash)
        })
        .catch((e) => {
            raiseError({
                message: e.message || `Error while calling /register`,
                debug: {
                    error: e,
                    url: `${SERVICE_ROOT}/register?${buildSignedURL(state.signed_hash, state.signed_payload)}`,
                    data,
                }
            })
        })
        .finally(() => {
            //
        })
    }

    /**
     * 
     * @param {*} data 
     */
    const setMerchantData = (data) => {
        dispatch({
            type: ACTION_TYPES.SET_MERCHANT_DATA,
            payload: data,
        })
    }

    /**
     * Handle the request by calling corresponding AWS Lambda function
     * 
     * @param {*} data 
     */
    const syncMerchantData = (data, signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
        showLoader();

        return postJSON(`${SERVICE_ROOT}/sync?${buildSignedURL(signed_hash, signed_payload)}`, data)
            .then(resp => {
                hideLoader()
                if (resp.message == 'Data Sync Initiated') {
                    return true;
                }
                return false;                
            })
            .catch((e) => {
                raiseError({
                    message: e.message || `Error while calling /sync`,
                    debug: {
                        error: e,
                        url: `${SERVICE_ROOT}/sync?${buildSignedURL(state.signed_hash, state.signed_payload)}`,
                        data,
                    }
                })
                return false;
            });
    }

    /**
     * Handle the request by calling corresponding AWS Lambda function
     * 
     * @param {*} data 
     */
     const toggleWebhookState = (data, signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
        const {resource} = data;
        return getJSON(`${SERVICE_ROOT}/toggle/${resource}?${buildSignedURL(signed_hash, signed_payload)}`)
            .then(resp => {
                loadMerchantData();
                if (resp.message === "Webhook Updated") {
                    return true;
                }
                return false;                
            }).catch((e) => {
                raiseError({
                    message: e.message || `Error while calling /toggle`,
                    debug: {
                        error: e,
                        url: `${SERVICE_ROOT}/toggle/${resource}?${buildSignedURL(signed_hash, signed_payload)}`,
                        data
                    }
                });
                return false;
            });
    }

    /**
     * Handle the request by calling corresponding AWS Lambda function
     * 
     * @param {*} data 
     */
    const saveHelpRequest = (data, signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
      showLoader()
      var sent = false;

      postJSON(`${SERVICE_ROOT}/contact?${buildSignedURL(signed_hash, signed_payload)}`, data)
      .then(data => {
        if(data.message && data.status) {
          setContactResponse(data.message, data.status); 
          sent = (data.status === "success");
        }
        hideLoader()
        setSignedHash(data.signed_hash)
      })
      .catch((e) => {
          raiseError({
              message: e.message || `Error while calling /contact`,
              debug: {
                  error: e,
                  url: `${SERVICE_ROOT}/contact?${buildSignedURL(state.signed_hash, state.signed_payload)}`,
                  data,
              }
          })
      })
      .finally(() => {
        return sent;
      });      
    }
    
    /**
     * Handle the request by calling corresponding AWS Lambda function
     * 
     * @param {*} data 
     */
    const saveMerchantSettings = (data, signed_hash = state.signed_hash, signed_payload = state.signed_payload) => {
      showLoader()

      postJSON(`${SERVICE_ROOT}/update?${buildSignedURL(signed_hash, signed_payload)}`, data)
      .then(data => {
          hideLoader()
          setSignedHash(data.signed_hash)
      })
      .catch((e) => {
          raiseError({
              message: e.message || `Error while calling /update`,
              debug: {
                  error: e,
                  url: `${SERVICE_ROOT}/update?${buildSignedURL(state.signed_hash, state.signed_payload)}`,
                  data,
              }
          })
      })
      .finally(() => {
          //
      })
    }

    return {
        showLoader,
        hideLoader,
        resetError,
        raiseError,
        setSignedData,
        setSignedPayload,
        loadMerchantData,
        registerMerchant,
        setMerchantData,
        saveMerchantSettings,
        saveHelpRequest,
        syncMerchantData,
        toggleWebhookState
    }
}