import { Button, StatefulTable, TableFigure } from "@bigcommerce/big-design";
import { DeleteIcon } from "@bigcommerce/big-design-icons";
import React from 'react';

const EmailList = ({ items, onDelete }) => {
  // const [emails, setEmails] = useState(items);

  const renderOnDelete = (email) => (
    <Button
      variant="subtle"
      actionType="destructive"
      onClick={(e) => {
        e.preventDefault()
        onDelete(email)
      }}
      iconOnly={<DeleteIcon title="Delete Email" />}
    />
  )

  return (
    <div>
      <div className='email-list-table'>
        <TableFigure>
          <StatefulTable
            columns={[
              { header: "Email", hash: "email", render: ({ email }) => email },
              {
                header: "",
                align: "right",
                hash: "actions",
                render: renderOnDelete
              }
            ]}
            items={items}
            stickyHeader
          />
        </TableFigure>
      </div>
    </div>
  )
}

export default EmailList
