import React from 'react'
import { Panel } from '@bigcommerce/big-design'

import { useStateValue } from '../store/state'

import logo from '../assets/listrak-logo.png'

const Logo = () => {
    return (
        <div className='dashboard-header-img'>
            <img src={logo} alt='Listrak' />
        </div>
    )
}

const MerchantId = () => {
    const { state } = useStateValue()

    const merchantId = state.merchant_data?.merchant?.LtkMerchantId

    if (!merchantId) {
        return null
    }

    return (
        <div className='dashboard-header-merch-id'>
            MerchantId: { merchantId }
        </div>
    )
}

const Container = ({ children }) => {
    return (
        <div className="container">
            <Panel>
                <div className='dashboard-header'>
                    <Logo />
                    <MerchantId />
                </div>
            </Panel>
            <Panel margin="medium">
                { children }
            </Panel>
        </div>
    )
}

export default Container;