// todo:  transition fetch to axios
// import axios from 'axios'

const validateResponse = (resp) => {
	if (resp.ok) {
		return Promise.resolve(resp)
	}

	// todo: might not be json, maybe handle better...
	return resp.json()
	.then(json => {
		throw new Error(json?.error?.message || JSON.stringify({
			error: json,
			response: resp,
		}))
	})
}

export const getJSON = (url) => {
	return fetch(url, {
		cache: 'no-cache',
	})
	.then(validateResponse)
	.then(resp => resp.json())
}

export const postJSON = (url, data) => {
	return fetch(url, {
        method: 'POST',
		cache: 'no-cache',
        body: JSON.stringify(data),
	})
	.then(validateResponse)
	.then(resp => resp.json())
}

export const putJSON = (url, data) => {
	return fetch(url, {
        method: 'PUT',
		cache: 'no-cache',
        body: JSON.stringify(data),
	})
	.then(validateResponse)
	.then(resp => resp.json())
}