import React from 'react'
import { Box, Button, Form, FormGroup, Input, Link, Panel, Textarea, Message } from "@bigcommerce/big-design"
import { useFormik } from 'formik'
import * as Yup from "yup"

import { useStateValue } from '../store/state'

const Help = () => {  

	const { state, actions } = useStateValue()
	const data = state.merchant_data?.merchant
	const merchantId = data.LtkMerchantId;
  const hasResponse = state.contact_response;

	const formik = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			merchantId: merchantId,
			comments: ''
		},
		validationSchema: Yup.object({
			merchantId: Yup.string().required('Merchant ID is required'),
			email: Yup.string().email('Email address is not valid').required('Email is required'),
			comments: Yup.string().required('Comments are required'),
		}),
		onSubmit: async (values) => {
			actions.saveHelpRequest(values);
		}
	})

	return (
		<div>
			<Box margin="large">
				Having trouble? Refer to our <Link href='https://help.listrak.com/en/articles/2293458-integration-guide-bigcommerce' target="_blank" external>User Guide</Link> for help. Need more assistance? Complete the form below to get in contact with our customer support team.
			</Box>
			<Panel>
        {state.contact_response?.message && hasResponse && (
          <Message
            type={state.contact_response.status}
            messages={[{ text: `${state.contact_response.message}` }]}
            marginBottom="xLarge"
          />
        )}
				<Form onSubmit={formik.handleSubmit} noValidate>
					<FormGroup>
						<Input
							label="First Name"
							name="firstName"
							type="text"
							{...formik.getFieldProps('firstName')}
						/>
						<Input
							label="Last Name"
							name="lastName"
							type="text"
							{...formik.getFieldProps('lastName')}
						/>
					</FormGroup>
					<FormGroup className='help-form-group'>
						<Input
							label="Email"
							name="email"
							type="text"
							required
							error={formik.touched.email && formik.errors.email}
							{...formik.getFieldProps('email')}
						/>
						<Input
							label="Merchant ID"
							name="merchantId"
							type="text"
							required
							disabled
							value={formik.values.merchantId}
						/>
					</FormGroup>
					<FormGroup>
						<Textarea
							label="Comments"
							name="comments"
							required
							error={formik.touched.comments && formik.errors.comments}
							{...formik.getFieldProps('comments')}
						/>
					</FormGroup>
					<Box marginTop="xxLarge">
						<div className="help-submit-btn-wrap">
							<Button type="submit">
								Submit
							</Button>
						</div>
					</Box>
				</Form>
			</Panel>
		</div>
	)
}

export default Help
