export const useQuery = (query_string) => {
		return new URLSearchParams(query_string)
}

export const buildSignedURL = (signed_hash, signed_payload) => {
	const query = new URLSearchParams() 

	if (signed_hash) {
		query.append(`signed_hash`, signed_hash)
	}  

	if (signed_payload) {
		query.append(`signed_payload`, signed_payload)
	}

	return query.toString()
}