import React from "react"
import { Box, Button, Form, FormGroup, H2, Input, Panel, Switch, Tooltip } from "@bigcommerce/big-design"
import { BaselineHelpIcon } from "@bigcommerce/big-design-icons"
import { useFormik } from 'formik'
import * as Yup from "yup"

import { useStateValue } from '../store/state'

import EmailList from "./EmailList"

const Settings = () => {
	const { state, actions } = useStateValue()
	const data = state.merchant_data?.merchant

	// todo: there might be a bug when there are no emails
	// const [emails, setEmails] = useState(data.emails)

	const formik = useFormik({
		initialValues: {
			autoPlace: data.Settings.AutoScriptPlacement,
			cart: data.Settings.CartTracking,
			product: data.Settings.ProductBrowse,
			emails: data.Emails,
			emailTracker: ''
		},
		validationSchema: Yup.object({
			emailTracker: Yup.string()//.email('Email address is not valid')
		}),
		onSubmit: (values) => {
			actions.saveMerchantSettings(values)
		}
	})

	const addEmail = (e) => {
		e.preventDefault()
		if (!formik.errors.emailTracker && formik.values.emailTracker) {
			formik.values.emails = [{ email: formik.values.emailTracker }, ...formik.values.emails]
			// setEmails(() => formik.values.emails)
			formik.values.emailTracker = ''
		}
	};

	const handleOnDeleteEmail = (deletedEmail) => {
		formik.values.emails = formik.values.emails.filter((email) => email.email !== deletedEmail.email)
		// setEmails((currentEmails) => formik.values.emails)
	}

	return (
		<div>
			<Form onSubmit={formik.handleSubmit} noValidate>
				<Panel marginBottom="xxLarge">
					<H2>Tracking Settings</H2>
					<div>
            By turning on these tracking settings, we will write the necessary scripts to your BigCommerce store. Please make sure you have not previously installed Listrak scripts manually to prevent issues with the installation.
					</div>
					<div className='tracking-switches'>
						<div className='tracking-switch'>
							<label>
								<Switch
									checked={formik.values.autoPlace}
									{...formik.getFieldProps('autoPlace')}
								/>
								<span className='tracking-switch-text'>Automatic Script Placement</span>
                <Tooltip trigger={<BaselineHelpIcon size='medium' />} placement='right'>
                  All tracking scripts are dependent on this setting being turned on and allowing the installation of our tracking script framework
								</Tooltip>
							</label>
						</div>
						<div className='tracking-switch'>
							<label>
								<Switch
									checked={formik.values.cart}
									{...formik.getFieldProps('cart')}
									margin='medium'
								/>
								<span className='tracking-switch-text'>Checkout Script</span>
								<Tooltip trigger={<BaselineHelpIcon size='medium' />} placement='right'>
                  Cart Abandonment tracks the user's cart activity so you can send emails encouraging them to complete their purchase.
								</Tooltip>
							</label>
						</div>
						<div className='tracking-switch'>
							<label>
								<Switch
									checked={formik.values.product}
									{...formik.getFieldProps('product')}
								/>
								<span className='tracking-switch-text'>Product Browse</span>
                <Tooltip trigger={<BaselineHelpIcon size='medium' />} placement='right'>
                  Browse Abandonment tracks what pages and products the user browses so you can send emails encouraging them to return to the website or add the products to their cart.
								</Tooltip>
              </label>
						</div>
					</div>
				</Panel>
				<hr />
				<Panel marginTop="xxLarge">
					<H2>Email Capture Inputs</H2>
					<div className='settings-tracker'>
						<div className='settings-tracker-form'>
							<div className='settings-tracker-form-group'>
								<FormGroup>
								<Input
									id='emailTracker'
									name='emailTracker'
									error={formik.touched.emailTracker && formik.errors.emailTracker}
									{...formik.getFieldProps('emailTracker')}
								/>
								<Button marginBottom="xLarge" onClick={addEmail} mobileWidth="100%">
									Add
								</Button>
							</FormGroup>
							</div>
							<EmailList items={formik.values.emails} onDelete={handleOnDeleteEmail} />
						</div>
						<div className='settings-tracker-text'>Email Capture is used to attach user activity such as product and cart abandonment to an email address that can be marketed to. You should enter all input field IDs used in your store that collect email address.</div>
					</div>
				</Panel>
				<Box marginTop="xxLarge">
					<div className="settings-tracker-btn-submit-wrap">
						<Button type="submit">
							Save Settings
						</Button>
					</div>
				</Box>
			</Form>
		</div>
	)
}

export default Settings;