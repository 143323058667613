import { ACTION_TYPES } from './actions'

export const initialState = {
    loading: false,
    error: null,
    signed_hash: null,
    signed_payload: null,
    merchant_data: null,
    contact_response: null
}

/**
 * Translate the incoming action into a state update
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export const reducer = (state = initialState, action) => {
  if (!action) {
    return state
  }

  switch(action.type) {
    case ACTION_TYPES.SHOW_LOADER:
      return {
        ...state,
        loading: true,
      }
    case ACTION_TYPES.HIDE_LOADER:
      return {
        ...state,
        loading: false,
      }
    case ACTION_TYPES.RESET_ERROR: 
      return {
        ...state,
        error: null,
      }
    case ACTION_TYPES.RAISE_ERROR: 
      return {
        ...state,
        error: action.payload,
      }
    case ACTION_TYPES.SET_SIGNED_HASH:            
      return {
        ...state,
        signed_hash: action.signed_hash,
      }
    case ACTION_TYPES.SET_SIGNED_PAYLOAD:
      return {
        ...state,
        signed_payload: action.signed_payload,
      }
    case ACTION_TYPES.SET_MERCHANT_DATA:
      return {
        ...state,
        signed_hash: action.payload.signed_hash,
        merchant_data: {
          isRegistered: action.payload.isRegistered,
          merchant: action.payload.merchant,    
        },
      }
    case ACTION_TYPES.SET_CONTACT_RESPONSE:
      return {
        ...state,
        contact_response: {
          message: action.message,
          status: action.status
        }
      };
    default:
      return {
        ...state
      }
  }
}