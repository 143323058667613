
// todo:  get these values from config!!
const debug = true

export const applyMiddleware = (state, dispatch) => action => {
    // Here we can inspect and modify the action before it is dispatched

    if (debug) {
        console.log('Action::', action, state)
    }

   dispatch(action)
}