import React, { useEffect, useState } from 'react'
import { Badge, Button, H2, Panel, Table, Switch } from "@bigcommerce/big-design"

import { useStateValue } from '../store/state'

const Status = () => {
	const { state, actions } = useStateValue();
	const data = state.merchant_data?.merchant

	const renderSyncStatus = (item) => {
		return (
		<>
			{item.status.map((i) => (
			<React.Fragment key={item.status.indexOf(i)}>
				<Badge
					marginBottom="xxSmall"
					marginTop="xxSmall"
					className={"centered"}
					variant={i.active ? "success" : "danger"}
					label={i.label}
				/>
				<br />
			</React.Fragment>
			))}
		</>
		);
	};

	const SyncBtn = ({ name: resource }) => {
		const [isSending, setSending] = useState(false);

		function sendDataSync(res) {
			const requestJson = {
				resource: res
			};

			return actions.syncMerchantData(requestJson);
		}
	
		useEffect(() => {
			if (isSending) {
				sendDataSync(resource).then(() => {
					setSending(false);
			  	});
			}
		  // eslint-disable-next-line react-hooks/exhaustive-deps
		  }, [isSending]);

		const handleClick = () => setSending(true);
	
		return (
			<Button className="sync-btn" isLoading={isSending}  onClick={!isSending ? handleClick : () => null} mobileWidth="100%">
				Sync Now
			</Button>
		);
	}	

	const Toggle = ({ enabled, name: resource }) => {
		const [isSending, setSending] = useState(false);
		const [isChecked, setIsChecked] = useState(enabled);

		function handleChange(res) {
			const requestData = {
				resource: res
			};

			return actions.toggleWebhookState(requestData);
		}

		useEffect(() => {
			if (isSending) {
				handleChange(resource).then(() => {					
					setSending(false);
					setIsChecked(!isChecked);
				}, (failed) => {
					setSending(false);
				})
			}
		});
	
		const handleToggle = () => setSending(true);

		return <Switch style={{justifyContent: "center"}} checked={isChecked} onChange={!isSending ? handleToggle : () => null} />
	}

	const transformDataSyncTime = ({Webhooks, WebhookStatus} = data) => {
		return ['Customer', 'Order', 'Product'].map(item => ({
			name: item,
      		status: WebhookStatus[item],
			lastSyncDateTime: Webhooks[item]["LastSync"],
			enabled: Webhooks[item]["Enabled"]
		}))
	}

	const renderSyncTime = ({ lastSyncDateTime }) => {
		return lastSyncDateTime !== '' ? lastSyncDateTime: 'n/a'
	}

	const items = transformDataSyncTime(data);

	return (
		<>
			<div className='status-tab'>
				<Panel className='status-container'>
					<H2>Data Sync Status</H2>
					<div className='status-panel'>
						<div className='status-panel-table'>
							<Table 
								columns={[
									{ header: 'Name', hash: 'name', render: ({ name }) => name },
									{ align: 'center', header: 'Sync Status', hash: 'status', render: renderSyncStatus },
									{ align: 'center', header: 'Last Event Time', hash: 'lastSyncDateTime', render: renderSyncTime },
									{ align: 'left', header: 'Historic Sync', hash: 'resource', render: SyncBtn },
									{ align: 'left', header: 'Enabled', hash: 'enabled', render: Toggle }
								]}
								items={items}
							/>
						</div>
						<div className='status-panel-text'>
							These are the current statuses for the data feeds that are supplied to Listrak and the last sync time for each feed.
						</div>
					</div>
				</Panel>
			</div>
		</>
	)
}

export default Status
