import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useStateValue } from '../store/state'
import { useQuery } from '../utils/url'

const Load = () => {
  const { state, actions } = useStateValue()
  const queryString = useQuery(useLocation().search)
	const navigate = useNavigate()

	useEffect(() => {
    actions.setSignedData(queryString)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
    if (state.merchant_data) {
      if (state.merchant_data.isRegistered) {
        return navigate(`/dashboard`)
      }
      return navigate(`/register`)
    }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ state.merchant_data ])

	return (
    <div>
      <p>
        Initializing...
      </p>
    </div>
	)
}

export default Load;